import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import Icon from "react-eva-icons";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import allWords from "./words.json";
import Word from "./Word";

ReactGA.initialize("UA-258145-1");
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

const Words = () => {
  const [eventTab, setEventTab] = useState(1);
  const [words, setWords] = useState([]);

  useEffect(() => {
    const data = [];
    allWords.forEach(obj => {
      if (obj.tags != null && obj.tags.includes("sat")) {
        data.push(obj);
      }
    });
    setWords(data);

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  const randomize = () => {
    setWords(shuffle(words));
  };

  const sortAZ = () => {
    words.sort(function(a, b) {
      var textA = a.word.toUpperCase();
      var textB = b.word.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setWords(words);
  };

  const sortZA = () => {
    words.sort(function(a, b) {
      var textA = a.word.toUpperCase();
      var textB = b.word.toUpperCase();
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    });

    setWords(words);
  };

  const handleSelect = eventKey => {
    setEventTab(eventKey);

    if (eventKey === "1") {
      sortAZ();
    }
    if (eventKey === "2") {
      sortZA();
    }
    if (eventKey === "3") {
      randomize();
    }
  };

  return (
    <>
      <Nav
        className="justify-content-center"
        variant="pills"
        activeKey={eventTab}
        onSelect={handleSelect}
      >
        <Nav.Item>
          <Nav.Link eventKey="1" href="#/home">
            <Icon
              name="arrow-down-outline"
              size="medium"
              fill="#CCC"
              style={{ verticalAlign: "middle" }}
            />
            <span className="ml-1" style={{ verticalAlign: "middle" }}>
              A to Z
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2" href="#/home">
            <Icon
              name="arrow-up-outline"
              size="medium"
              fill="#CCC"
              style={{ verticalAlign: "middle" }}
            />
            <span className="ml-1" style={{ verticalAlign: "middle" }}>
              Z to A
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="3" title="Item">
            <Icon
              name="shuffle-2-outline"
              size="medium"
              fill="#CCC"
              style={{ verticalAlign: "middle" }}
            />
            <span className="ml-1" style={{ verticalAlign: "middle" }}>
              Random
            </span>
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <div className="flex-container justify-content-start">
        {words.map(word => (
          <Word word={word} key={word.word} />
        ))}
      </div>
    </>
  );
};
export default Words;
