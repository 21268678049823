import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import * as Constants from "./Constants";

const Navigation = () => {
  return (
    <>
      <Navbar bg="warning" className="navbar-dark">
        <Navbar.Brand href="#home">{Constants.Brand}</Navbar.Brand>
        <Nav className="ml-auto">
          <Nav.Link
            href="https://instavocab.com"
            target="_blank"
            style={{ color: "#FFF" }}
          >
            Instavocab
          </Nav.Link>
          <Nav.Link
            href="https://toeflvocabulary.com"
            target="_blank"
            style={{ color: "#FFF" }}
          >
            TOEFL Words
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
};
export default Navigation;
