import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

import * as Constants from "./Constants";
import Navigation from "./Navigation";
import Message from "./Message";
import Words from "./Words";
import "./App.css";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Constants.Title}</title>
        <meta name="description" content={Constants.Description} />
      </Helmet>
      <Navigation />

      <Container className="mt-4">
        <Message />
        <Words />
      </Container>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
