import React from "react";
import { Card } from "react-bootstrap";

const Word = props => {
  const { word } = props;
  return (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title>
          {word.word} <small className="text-muted">{word.partOfSpeech}</small>
        </Card.Title>
        <Card.Text>{word.definition}</Card.Text>

        {word.soundsLike != null &&
          (word.soundsLike !== "" && (
            <Card.Text>Sounds like: {word.soundsLike}</Card.Text>
          ))}
        {word.looksLike != null &&
          (word.looksLike !== "" && (
            <Card.Text>Looks like: {word.looksLike}</Card.Text>
          ))}
        {word.rememberThis != null &&
          (word.rememberThis !== "" && (
            <Card.Text>Remember this: {word.rememberThis}</Card.Text>
          ))}
        {word.imagineThis != null &&
          (word.imagineThis !== "" && (
            <Card.Text>Imagine this: {word.imagineThis}</Card.Text>
          ))}
      </Card.Body>
    </Card>
  );
};
export default Word;
