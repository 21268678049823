import React from "react";
import { Alert, Button } from "react-bootstrap";
import * as Constants from "./Constants";

const Message = () => {
  return (
    <>
      <Alert variant="light">
        <Alert.Heading>{Constants.MainMessage}</Alert.Heading>
        <p>{Constants.SubMessage}</p>

        <hr />

        <div className="justify-content-center">
          <h4 className="text-center">
            Get the App
            <span role="img" aria-label="phone">
              📱
            </span>
          </h4>
          <a
            href="https://apps.apple.com/us/app/instavocab/id1480190766?ls=1"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            <Button variant="primary" className="mb-2" size="lg" block>
               App Store
            </Button>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.instavocab"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
          >
            <Button variant="primary" size="lg" block>
              Google Play
            </Button>
          </a>
        </div>
      </Alert>
    </>
  );
};
export default Message;
